<template>
	<div style="height: 100%;">
		<el-menu :default-active="onActive" class="el-menu-vertical-demo" :collapse="collapse" collapse-transition unique-opened text-color="#fff" router>
			<template v-for="(item, mindex) in menus">
				<template v-if="item.children && item.children[0].type == 1">
					<el-submenu :index="item.uri || 'm' + mindex" :key="mindex">
						<template slot="title">
							<i :class="item.icon"></i>
							<span>{{ item.name }}</span>
						</template>
						<template v-for="(subitem, sindex) in item.children">
							<template v-if="subitem.children && subitem.children[0].type == 1">
								<el-submenu :index="subitem.uri || 's' + sindex" :key="sindex">
									<template slot="title">
										<i :class="subitem.icon"></i>
										<span>{{ subitem.name }}</span>
									</template>
									<el-menu-item
										v-for="(thirditem, tindex) in subitem.children"
										:index="thirditem.uri || 't' + tindex"
										:key="tindex"
										@click="setListTitle(thirditem,[item,subitem,thirditem])"
									>
										<i :class="thirditem.icon"></i>
										<span>{{ thirditem.name }}</span>
									</el-menu-item>
								</el-submenu>
							</template>
							<template v-else>
								<el-menu-item :index="subitem.uri || 's' + sindex" :key="sindex" @click="setListTitle(subitem,[item,subitem])">
									<i :class="subitem.icon"></i>
									<span>{{ subitem.name }}</span>
								</el-menu-item>
							</template>
						</template>
					</el-submenu>
				</template>
				<template v-else>
					<el-menu-item :index="item.uri || 'm' + mindex" :key="mindex" :title="item.name" @click="setListTitle(item,[item])">
						<i :class="item.icon"></i>
						<span>{{ item.name }}</span>
					</el-menu-item>
				</template>
			</template>
		</el-menu>
	</div>
</template>

<script>
import { mapState } from 'vuex';
import { $vue } from '../main.js';
export default {
	data() {
		return {
			navlist:[],
			menus: [
				{
					icon: 'el-icon-s-home',
					uri: '/index',
					name: '首頁'
				},
				{
					icon: 'el-icon-rank',
					uri: '/Categories?custom=1&customform=1', //custom自定义列表js，customform自定义表单js
					name: '通用列表'
				},
				{
					icon: 'el-icon-rank',
					uri: '',
					name: '系统设置',
					children: [
						{
							uri: '/system/Role?custom=1',
							name: '角色',
							type: 1
						},
						{
							uri: '/system/User?custom=1',
							name: '用户'
						},
						{
							uri: '/Right',
							name: '权限'
						},
						{
							uri: '3',
							name: '三级菜单',
							children: [
								{
									uri: '/thirdmenu',
									name: '三级1',
									type: 1
								},
								{
									uri: '/markdown',
									name: '三级2'
								}
							]
						}
					]
				}
			]
		};
	},
	computed: {
		...mapState({
			userData: state => state.user.userData,
			collapse: state => state.config.collapse
		}),
		onActive() {
			return this.$route.path.replace('/', '');
		}
	},
	created() {
		// this.$store.commit('config/config_setCurrentNavigater', [{name:"主页",uri:"/index"}]);
		this.$store.commit('tags/tags_addTagsArr', {name:"主页",uri:"/index"});
		// this.$store.commit('config/config_setAllNavigater',[[{name:"主页",uri:"/index"}]]);
		this.getMenuList();
	},
	mounted() {
		this.$vue.$on('refreshMenuList', () => {
			this.getMenuList();
		});
	},
	methods: {
		setListTitle(item,arrItem) {
			console.log( item)
			this.$store.commit('right/right_setBtnList', item.children);
			this.$store.commit("config/config_setCurrentRouterItem",item)
			this.$store.commit('config/config_setCurrentRouterTitle', item.name);
			// this.$store.commit("config/config_setCurrentRouterId",item.id)
			this.$store.commit('tags/tags_addTagsArr', item);
			let arr =[{name:"主页",uri:"/index"}]
			if (!arrItem.some(v => v.uri === "/index")){
				arr = arr.concat(arrItem)
			}
			this.$store.commit('config/config_setCurrentNavigater', arr);
			if(!this.navlist.some(v => v[v.length-1].name === arr[arr.length-1].name)){
				this.navlist.push(arr)
			}
			this.$store.commit('config/config_setAllNavigater', this.navlist);
		},
		async getMenuList() {
			const res = await this.$axios('GET', `/sys/sysPermission/Treelist`, {}, true);
			this.menus = res;
			this.$store.commit('right/right_setMenuList', this.menus);
		}
	}
};
</script>

<style lang="scss">
	.el-menu-vertical-demo {
		height: 100%;
		border: 0;
	}
	.el-menu {
		background-color: $left_bgcolor;
	}
	.el-menu-item {
		display: flex;
		align-items: center;
		// &:hover span{
		// 	color: #242F42;
		// }
	}
	// .el-submenu__title{
	// 	&:hover span{
	// 		color: #242F42;
	// 	}
	// }
	.el-submenu__title:focus,
	.el-submenu__title:hover {
		// background-color: #a1b76e;
		background-color: #376ab5;
	}
	.el-menu-item:focus,
	.el-menu-item:hover {
		background-color: #376ab5;
		// background-color: #a1b76e;
	}
</style>
