<template>
	<div class="listtitle">
		<div class="top-navigation">
			<el-button
				type="primary"
				size="small"
				plain
				:icon="item.icon"
				v-for="(item, index) in tagsArr"
				v-bind:key="index"
				:class="[isActive(item) ? 'active' : '']"
				@click="routerLink(item)"
			>
				<span class="item-name">{{ item.name }}</span>
				<span :class="['item-close', isActive(item) ? 'active' : '']" @click="closeItem(item)">×</span>
			</el-button>
		</div>
	</div>
</template>

<script>
import { mapState } from 'vuex';
export default {
	name: 'navigationBar',
	props: {
		navigationList: {
			type: Array,
			default: () => {}
		}
	},
	data() {
		return {
			currentIndex: 0,
			navlist: []
		};
	},
	computed: {
		...mapState({
			userData: state => state.user.userData,
			collapse: state => state.config.collapse,
			tagsArr: state => state.tags.tagsArr,
			allNavigater: state => state.config.allNavigater,
		})
	},
	methods: {
		isActive(route) {
			return route.uri === this.$route.fullPath; 
		},
		routerLink(item){
			console.log(item)
			this.$router.push(item.uri)
			this.$store.commit("right/right_setBtnList",item.children)
			if (item.uri === "/index"){
				this.$store.commit('config/config_setCurrentNavigater', [item]);
			}
			this.allNavigater.some(v =>{
				if(v[v.length-1].name === item.name){
					this.$store.commit('config/config_setCurrentNavigater', v);
				}
			})
		},
		closeItem(item) {
			this.$store.commit("tags/tags_delTagsArr",item)
		}
	}
};
</script>

<style lang="scss" scoped>
.listtitle {
	// padding-bottom: 10px;
	// border-bottom: 1px solid #ebebeb;
	// margin-bottom: 20px;
	padding: 0 20px;
	//面包屑导航样式
	.top-navigation {
		.el-button + .el-button {
			margin-left: 6px;
		}
		a {
			text-decoration: none;
			color: #fff;
		}
		button.el-button {
			min-width: 80px;
			border-radius: 0;
			border: 0;
			border-color: #ADB6C4;
			position: relative;
			box-sizing: border-box;
			border-left: 0;
			border-right: 0;
			color: #fff;
			// &:nth-child(2n) {
				border-radius: 2px 0 0 2px;
				background-color: #ADB6C4;
				&::before {
					position: absolute;
					box-sizing: border-box;
					z-index: 1;
					top: 0px;
					left: -1px;
					bottom: 0;
					content: '';
					width: 0;
					height: 0;
					border-top: 15px solid transparent;
					border-left: 12px solid #fff;
					border-bottom: 15px solid transparent;
				}
				&::after {
					position: absolute;
					box-sizing: border-box;
					z-index: 9;
					top: 0px;
					right: -12px;
					bottom: 0;
					content: '';
					width: 0;
					height: 0;
					border-top: 15px solid transparent;
					border-left: 12px solid #ADB6C4;
					border-bottom: 15px solid transparent;
				}
			// }
			// &:nth-child(2n + 1) {
			// 	border-radius: 2px 0 0 2px;
			// 	background-color: #242f42 !important;
				// margin-left: 4px !important;
			// 	&::before {
			// 		position: absolute;
			// 		box-sizing: border-box;
			// 		z-index: 1;
			// 		top: -1px;
			// 		left: -1px;
			// 		bottom: 0;
			// 		content: '';
			// 		width: 0;
			// 		height: 0;
			// 		border-top: 16px solid transparent;
			// 		border-left: 12px solid #fff;
			// 		border-bottom: 16px solid transparent;
			// 	}
			// 	&::after {
			// 		position: absolute;
			// 		box-sizing: border-box;
			// 		z-index: 9;
			// 		top: -1px;
			// 		right: -12px;
			// 		bottom: 0;
			// 		content: '';
			// 		width: 0;
			// 		height: 0;
			// 		border-top: 16px solid transparent;
			// 		border-left: 12px solid #242f42;
			// 		border-bottom: 16px solid transparent;
			// 	}
			// }
			&:first-child {
				border-radius: 2px 0 0 2px;
				margin-left: 0 !important;
				&::before {
					display: none;
				}
			}
			// &:last-child{
			// 	border-radius: 2px 0 0 2px;
			// 	&::after{
			// 		display: none;
			// 	}
			// }
			.item-name{
				display: inline-block;
				margin-right: 4px;
			}
			.item-close {
				position: absolute;
				right: -2px;
				top: 7px;
				z-index: 999;
				display: inline-block;
				box-sizing: border-box;
				width: 16px;
				height: 16px;
				line-height: 16px;
				text-align: center;
				border-radius: 10px;
			}
			.item-close.active {
				border: 0;
				border: 1px solid #fff;
			}
		}
		button.el-button.active {
			background-color: #A1B76E;
			color: #fff;
			&::after {
				border-left-color:#A1B76E;
			}
		}
	}
}
</style>
