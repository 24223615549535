<template>
	<div class="home">
		<el-container class="all-container">
			<el-aside :width="collapse ? '65px' : '210px'">
				<el-container>
					<el-header height="50px" class="logo-title-area">
						<div class="logo-img"><img src="../assets/img/logo.png" /></div>
						<span v-if="!collapse">后台管理系统</span>
					</el-header>
					<happy-scroll color="#740411" hide-horizontal size="0" :min-length-v="0" resize right><MenuBar></MenuBar></happy-scroll>
				</el-container>
			</el-aside>
			<el-container class="right-container">
				<el-header height="50px"><TopBar></TopBar></el-header>
				<!-- <scroll-pane class="swiper-item" ref="scrollPane"><navigationBar :navigationList="navigationList"></navigationBar></scroll-pane> -->
				<el-main>
					<transition name="nodebook" mode="out-in"><router-view /></transition>
				</el-main>
			</el-container>
		</el-container>
	</div>
</template>

<script>
import TopBar from '../components/topBar.vue';
import MenuBar from '../components/menuBar.vue';
import navigationBar from '../components/navigationBar.vue';
import scrollPane from '../components/scrollPane.vue';
import { mapState } from 'vuex';
export default {
	name: 'home',
	data() {
		return {
			iscollapse: false,
			navigationList: []
		};
	},
	components: {
		TopBar,
		MenuBar,
		navigationBar,
		scrollPane
	},
	computed: {
		...mapState({
			collapse: state => state.config.collapse
		})
	},
	mounted() {
		console.log(this.collapse);
	},
	methods: {
		// 侧边栏折叠
		collapseChage() {
			this.iscollapse = !this.iscollapse;
			console.log(this.iscollapse);
			this.$store.commit('config/config_setCollapse', this.iscollapse);
		}
	}
};
</script>
<style lang="scss">
	.home {
		height: 100%;
		box-sizing: border-box;
	}
	.all-container {
		height: 100%;
		box-sizing: border-box;
		display: flex;
		.logo-title-area {
			@include border-after;
			@include flex-style(row, nowrap, center, center);
			border-right: 1px solid rgba(255, 255, 255, 0.4);
			color: #fff;
			font-size: 20px;
			padding: 0;
			background: $header_bgcolor;
			.logo-img {
				width: 40px;
				height: 40px;
				border-radius: 20px;
				overflow: hidden;
				img {
					width: 100%;
					height: 100%;
				}
			}
			span {
				margin-left: 8px;
				height: 20px;
				line-height: 20px;
				overflow: hidden;
			}
		}
	}
	.right-container {
		flex: 1;
		height: 100%;
		overflow-y: auto;
		.swiper-item {
			box-sizing: border-box;
			height: 42px;
			padding: 4px 20px 2px;
			background: rgba(255, 255, 255, 0.8);
			border-bottom: 1px solid #d8dce5;
			box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 0 3px 0 rgba(0, 0, 0, 0.04);
		}
		.el-header {
			background-color: $header_bgcolor;
		}
	}
	.el-aside {
		width: 100%;
		height: 100%;
		box-sizing: border-box;
		transition: all 0.5s;
		background: $left_bgcolor;
		.happy-scroll-container {
			width: 100% !important;
			height: 100% !important;
			.happy-scroll-content {
				width: 100%;
			}
		}
	}
	.el-container .el-main {
		background-color: #fff;
		height: calc(100% - 42px);
		width: 100%;
		padding: 16px 20px 20px;
	}

	.nodebook-enter,
	.nodebook-leave-to {
		transform: scale(0.9);
		opacity: 0;
	}

	.nodebook-enter-to,
	.nodebook-leave {
		transform: scale(1);
		opacity: 1;
	}
	.nodebook-enter-active,
	.nodebook-leave-active {
		transition: all 0.3s;
	}
</style>
