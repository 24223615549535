<template>
	<div class="header">
		<!-- 折叠按钮 -->
		<div class="collapse-btn" @click="collapseChage"><i :class="['el-icon-s-unfold', collapse ? 'active' : '']"></i></div>
		<div class="top-navigater">
			<el-breadcrumb separator="/">
				<template v-for="(item,index) in currentNavigater">
					<el-breadcrumb-item v-if="item.uri=='/index'"  :to="{ path: '/'}" :key="index">{{item.name}}</el-breadcrumb-item>
					<el-breadcrumb-item v-else :key="index">{{item.name}}</el-breadcrumb-item>
				</template>
			</el-breadcrumb>
		</div>
		<div class="header-right">
			<div class="header-user-con">
				<!-- 全屏显示 -->
				<div class="btn-fullscreen" @click="handleFullScreen">
					<el-tooltip effect="dark" :content="fullscreen ? `取消全屏` : `全屏`" placement="bottom"><i class="el-icon-rank"></i></el-tooltip>
				</div>
				<!-- 消息中心 -->
				<div class="btn-bell">
					<el-tooltip effect="dark" :content="message ? `有${message}条未读消息` : `消息中心`" placement="bottom">
						<router-link to="/tabs"><i class="el-icon-bell"></i></router-link>
					</el-tooltip>
					<span class="btn-bell-badge" v-if="message"></span>
				</div>
				<!-- 用户头像 -->
				<div class="user-avator"><img :src="userData.icon || require('../assets/img/img.jpg')" /></div>
				<!-- 用户名下拉菜单 -->
				<el-dropdown class="user-name" trigger="click" @command="handleCommand">
					<span class="el-dropdown-link">
						{{ userData.nickName || '管理员' }}
						<i class="el-icon-caret-bottom"></i>
					</span>
					<el-dropdown-menu slot="dropdown"><el-dropdown-item divided command="loginout">退出登录</el-dropdown-item></el-dropdown-menu>
				</el-dropdown>
			</div>
		</div>
	</div>
</template>
<script>
import { mapState } from 'vuex';
export default {
	data() {
		return {
			iscollapse: false,
			fullscreen: false,
			message: 2
		};
	},
	computed: {
		...mapState({
			userData: state => state.user.userData,
			currentNavigater: state => state.config.currentNavigater
		}),
		collapse: {
			get(){
				return this.$store.state.config.collapse
			},
			set(){
				return this.$store.commit('config/config_setCollapse', this.iscollapse)
			}
		}
	},
	mounted() {
		window.onresize = () => {
			if (document.body.clientWidth < 1500) {
				this.collapse = true;
				this.$store.commit('config/config_setCollapse', true);
			} else {
				this.collapse = false;
				this.$store.commit('config/config_setCollapse', false);
			}
		};
	},
	methods: {
		// 用户名下拉菜单选择事件
		handleCommand(command) {
			if (command == 'loginout') {
				this.$store.commit('user/user_logout');
				this.$router.push('/login');
			}
		},
		// 侧边栏折叠
		collapseChage() {
			this.iscollapse = !this.iscollapse;
			this.$store.commit('config/config_setCollapse', this.iscollapse);
		},
		// 全屏事件
		handleFullScreen() {
			let element = document.documentElement;
			if (this.fullscreen) {
				if (document.exitFullscreen) {
					document.exitFullscreen();
				} else if (document.webkitCancelFullScreen) {
					document.webkitCancelFullScreen();
				} else if (document.mozCancelFullScreen) {
					document.mozCancelFullScreen();
				} else if (document.msExitFullscreen) {
					document.msExitFullscreen();
				}
			} else {
				if (element.requestFullscreen) {
					element.requestFullscreen();
				} else if (element.webkitRequestFullScreen) {
					element.webkitRequestFullScreen();
				} else if (element.mozRequestFullScreen) {
					element.mozRequestFullScreen();
				} else if (element.msRequestFullscreen) {
					// IE11
					element.msRequestFullscreen();
				}
			}
			this.fullscreen = !this.fullscreen;
		}
	}
};
</script>
<style lang="scss">
.header {
	position: relative;
	box-sizing: border-box;
	width: 100%;
	height: 50px;
	font-size: 22px;
	color: #fff;
	.top-navigater {
		float: left;
		margin-left: 20px;
		margin-top: 17px;
		.el-breadcrumb__inner {
			color: #bdbdbd;
		}
		.el-breadcrumb__inner a,
		.el-breadcrumb__inner.is-link {
			transition: color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
			color: #fff;
		}
		.breadcrumb-enter-active,
		.breadcrumb-leave-active{
			transform: all .5s;
		}
		
		.breadcrumb-enter,
		.breadcrumb-leave-active{
			opacity: 0;
			transform: translateX(20px);
		}
		.breadcrumb-move{
			transition: all 0.5s;
		}
	}
}
.collapse-btn {
	float: left;
	padding: 0;
	cursor: pointer;
	line-height: 50px;
	.el-icon-s-unfold {
		animation: myend 0.5s linear 0s 1 alternate forwards;
		-webkit-animation: myend 0.5s linear 0s 1 alternate forwards;
	}
	.el-icon-s-unfold.active {
		animation: myfirst 0.5s linear 0s 1 alternate forwards;
		-webkit-animation: myfirst 0.5s linear 0s 1 alternate forwards;
	}
}

.header-right {
	float: right;
	padding-right: 50px;
}
.header-user-con {
	display: flex;
	height: 50px;
	align-items: center;
}
.btn-fullscreen {
	transform: rotate(45deg);
	margin-right: 5px;
	font-size: 24px;
}
.btn-bell,
.btn-fullscreen {
	position: relative;
	width: 30px;
	height: 30px;
	text-align: center;
	border-radius: 15px;
	cursor: pointer;
}
.btn-bell-badge {
	position: absolute;
	right: 0;
	top: -2px;
	width: 8px;
	height: 8px;
	border-radius: 4px;
	background: #f56c6c;
	color: #fff;
}
.btn-bell .el-icon-bell {
	color: #fff;
}
.user-name {
	margin-left: 10px;
}
.user-avator {
	margin-left: 20px;
}
.user-avator img {
	display: block;
	width: 40px;
	height: 40px;
	border-radius: 50%;
}
.el-dropdown-link {
	color: #fff;
	cursor: pointer;
}
.el-dropdown-menu__item {
	text-align: center;
}

@keyframes myfirst {
	from {
		transform: rotate(0deg);
		-ms-transform: rotate(0deg); /* IE 9 */
		-webkit-transform: rotate(0deg); /* Safari and Chrome */
	}
	to {
		transform: rotate(180deg);
		-ms-transform: rotate(180deg); /* IE 9 */
		-webkit-transform: rotate(180deg); /* Safari and Chrome */
	}
}

@-webkit-keyframes myfirst /* Safari 与 Chrome */ {
	from {
		transform: rotate(0deg);
		-ms-transform: rotate(0deg); /* IE 9 */
		-webkit-transform: rotate(0deg); /* Safari and Chrome */
	}
	to {
		transform: rotate(180deg);
		-ms-transform: rotate(180deg); /* IE 9 */
		-webkit-transform: rotate(180deg); /* Safari and Chrome */
	}
}
@keyframes myend {
	from {
		transform: rotate(180deg);
		-ms-transform: rotate(180deg); /* IE 9 */
		-webkit-transform: rotate(180deg); /* Safari and Chrome */
	}
	to {
		transform: rotate(0deg);
		-ms-transform: rotate(0deg); /* IE 9 */
		-webkit-transform: rotate(0deg); /* Safari and Chrome */
	}
}

@-webkit-keyframes myend /* Safari 与 Chrome */ {
	from {
		transform: rotate(180deg);
		-ms-transform: rotate(180deg); /* IE 9 */
		-webkit-transform: rotate(180deg); /* Safari and Chrome */
	}
	to {
		transform: rotate(0deg);
		-ms-transform: rotate(0deg); /* IE 9 */
		-webkit-transform: rotate(0deg); /* Safari and Chrome */
	}
}
</style>
